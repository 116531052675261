<template>
  <div>
      <!-- BODY -->
      <b-overlay :show="loading" :variant="$store.state.appConfig.layout.skin" >
      <validation-observer
        #default="{ handleSubmit }"
        ref="simpleRules"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- firstname -->
          <validation-provider
            #default="validationContext"
            name="First Name"
            rules="required"
          >
            <b-form-group
              label="First Name"
              label-for="firstname"
            >
              <b-form-input
                id="firstname"
                v-model="userData.firstName"
                autofocus
                trim
                :state="validationContext.errors.length > 0 ? false : null"
                placeholder="Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- lastname -->
          <validation-provider
            #default="validationContext"
            name="Last Name"
            rules="required"
          >
            <b-form-group
              label="Last Name"
              label-for="lastname"
            >
              <b-form-input
                id="lastname"
                v-model="userData.lastName"
                trim
                :state="validationContext.errors.length > 0 ? false : null"
                placeholder="Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

           <!-- username -->
          <validation-provider
            #default="validationContext"
            name="User name"
            rules="required"
          >
            <b-form-group
              label="User name"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="userData.userName"
                trim
                :state="validationContext.errors.length > 0 ? false : null"
                placeholder="UserName"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                trim
                :state="validationContext.errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required|password"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="userData.password"
                trim
                :state="validationContext.errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Confirm Password -->
          <validation-provider
            #default="validationContext"
            name="ConfirmPassword"
            rules="required|confirmed:Password"
          >
            <b-form-group
              label="ConfirmPassword"
              label-for="confirmpassword"
            >
              <b-form-input
                id="confirmpassword"
                v-model="userData.confirmpassword"
                trim
                :state="validationContext.errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Currencies -->
        <div
            class="
            d-flex
            justify-content-between
            align-items-center
            mb-1"
        >
            <h5 class="mb-0">{{ $t('user-list.text.selectCurrencies') }}</h5>
            <b-button
            @click="showSeachCurrency=!showSeachCurrency"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon rounded-circle"
            size="sm"
            >
            <feather-icon icon="SearchIcon" />
            </b-button>
        </div>
        <b-form-input
          v-if="showSeachCurrency"
          v-model="searchQueryCurrency"
          class="d-inline-block mb-1"
          placeholder="Search Currency..."
          size="sm"
        />
        <b-card
            ref="content"
            style="position: relative; height: 150px; overflow-y: scroll"
            no-body
        >
        <b-overlay :show="loadingCurrencies" :variant="$store.state.appConfig.layout.skin" >
        <b-list-group>
            <!-- select all -->
            <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            >
            <span>{{ $t('user-list.text.selectAll') }}</span>
            <b-form-checkbox
                v-model="selectAllCurrencies"
                @change="selectAllCurrencyMethode"
            />
            </b-list-group-item>

            <b-list-group-item
            v-for="(tr, index) in currenciesOptionsSearch"
            :key="index"
            class="d-flex justify-content-between align-items-center"
            >
            <span>{{tr.abbreviation}}</span>

            <b-form-checkbox
                v-model="userData.currencies"
                :value="tr.abbreviation"
                class="custom-control-primary"
            />
            </b-list-group-item>
        </b-list-group>
        </b-overlay>
        </b-card>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
              <!--  -->
            
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                >
                <feather-icon icon="SaveIcon" class="mr-1"/>
                  {{ $t('buttons.save') }}
                </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$emit('hidenSidebar')"
            >
            <feather-icon icon="XIcon" class="mr-1"/>
            {{ $t('buttons.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
      </b-overlay>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect,BFormCheckbox, BOverlay,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormCheckboxGroup,
    BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email} from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { translatableText } from '@/@core/utils/utils'
import { axiosErrorHandle } from '@core/utils/errorHandler'


export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BFormSelect,
        BFormCheckbox,
        BOverlay,
        BListGroup,
        BListGroupItem,
        BBadge,
        BFormCheckboxGroup,
        BCard,
        
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    data: () => ({
        loading: false,
        loadingCurrencies: false,
        userData: {
            firstName: '',
            lastName: '',
            userName: '',
            email: '',
            password: '',
            confirmPassword: '',
            currencies: [],
        },
        currenciesOptions: [],
        required,
        alphaNum,
        email,
        showSeachCurrency: false,
        searchQueryCurrency: '',
    }),
    created() {
        this.fetchCurrencies()
    },
    computed: {
        currenciesOptionsSearch(){
            return this.currenciesOptions.filter(tr => {
                return tr.abbreviation.toLowerCase().includes(this.searchQueryCurrency.toLowerCase())
            })
        },
        selectAllCurrencies: {
            get() {
                return this.userData.currencies.length === this.currenciesOptions.length
            },
            set(value) {
            },
        },
    },
    methods:{
        async onSubmit(){
            try {
                const validation = this.$refs.simpleRules.validate()
                if(!validation)return;
                this.loading = true
                await store.dispatch('app-user/addPlayer', this.userData) 
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.$t('user-list.messages.userCreated'),
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.resetFrom()
                this.$emit('hidenSidebar')
            } catch (error) {
                console.log(error.response)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: await translatableText({ text: axiosErrorHandle(error)}),
                        icon: 'ErrorIcon',
                        variant: 'danger',
                    },
                })                
            }finally{
                this.loading = false
            }

        },
        resetFrom(){
            this.userData = {
            firstName: '',
            lastName: '',
            userName: '',
            email: '',
            password: '',
            confirmPassword: '',
            currencies: [],
            }
        },

        async fetchCurrencies(){
            try {
                this.loadingCurrencies = true
                const currencies = await store.dispatch('app-whitelabel/fetchCurrencies')
                this.currenciesOptions = currencies.data
                console.log(currencies)
            } catch (error) {
                console.log(error)
            }finally{
                this.loadingCurrencies = false
            }
        },
        selectAllCurrencyMethode(){
            if(this.selectAllCurrencies){
                this.userData.currencies = []
            }else{
                this.userData.currencies = this.currenciesOptions.map(tr => tr.abbreviation)
            }
        },

    }
}
</script>

<style>

</style>