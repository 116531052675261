<template>
      <div >
        <b-card style="max-width: 50rem;" >
          <b-card-title >
            <h5 class="p-1">
              User balance:  {{walletData.balanceUserSelected}}
            </h5>
            <h5 class="p-1">
              Your balance: {{walletData.balanceUserLogged}}
            </h5>
          </b-card-title>
            
        <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
        >

        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
         
          <!-- Currencies -->
          <validation-provider
            #default="validationContext"
            name="Currency"
            rules="required"
          >
            <b-form-group
              label="Currency"
              label-for="currency"
            >
              <b-form-select
                input-id="currency"
                v-model="walletData.currencies"
                :options="clientCurrencies"
                value-field="abbreviation"
                text-field="name"
               
                @input="getBalance({ currency: $event})"
                :state="getValidationState(validationContext)"
              />
              
              <template #code>
                {{ codeMultiple }}
              </template>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

            </b-form-group>
          </validation-provider>

          <!-- Type -->
          <validation-provider
            #default="validationContext"
            name="type"
            rules="required"
          >
            <b-form-group
              label="Type"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <b-form-select
                input-id="type"
                v-model="walletData.details.type"
                :options="optionsTypes"
                value-field="value"
                text-field="text"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- amount -->
          <validation-provider
            #default="validationContext"
            name="amount"
            rules="required"
          >
            <b-form-group
              label="amount"
              label-for="amount"
            >
              <b-form-input
                id="amount"
                type="number"
                v-model="walletData.amount"
                @input="allowTransaction({ amount: $event})"
                :state="getValidationState(validationContext)"
              />
             
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- reference -->
          <validation-provider
            #default="validationContext"
            name="reference"
            rules="required"
          >
            <b-form-group
              label="Reference"
              label-for="reference"
            >
              <b-form-input
                id="reference"
                v-model="walletData.reference"
                :state="getValidationState(validationContext)"
                trim
               
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- description -->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
          >
            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="walletData.details.description"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
         
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
          
          </div>
          
        </b-form>
        </validation-observer>
        </b-card>
      </div>
   
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect,BFormCheckbox, BCard, BCardTitle,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store'
import { codeMultiple } from '../../../forms/form-element/form-select/code'
import whitelabelStoreModule from '../../../pages/whitelabel/whitelabelStoreModule'
import userStoreModule from '../userStoreModule'
import clientStoreModule from '../../../pages/clients/clientStoreModule'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    BFormCheckbox,
    vSelect,
    BCard,
    BButton,
    BCardTitle,
    
  
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  
  data() {
    return {
      required,
      alphaNum,
      codeMultiple,
      optionsTypes: [
        { value: 'debit', text: 'Debit' },
        { value: 'credit', text: 'Credit' }
      ],
    }
  },
  setup(props, { emit }) {

    const WHITELABEL_APP_STORE_MODULE_NAME = 'app-whitelabel'
     const USER_APP_STORE_MODULE_NAME = 'app-user'
     const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    if (!store.hasModule(WHITELABEL_APP_STORE_MODULE_NAME)) store.registerModule(WHITELABEL_APP_STORE_MODULE_NAME, whitelabelStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    onUnmounted(() => {
      if (store.hasModule(WHITELABEL_APP_STORE_MODULE_NAME)) store.unregisterModule(WHITELABEL_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    })

    const wallets = ref([])
    store.dispatch('app-user/fetchUserWallets')
      .then(response => { 
        wallets.value = response
      }) 
      
    const clients = ref([])
    const clientCurrencies = ref([])
    
    store.dispatch('app-client/fetchClients')
      .then(response => { 
        clients.value = response.data.clients
        console.log('clientes: ', clients.value)
      })  
      .then( () => {    
        const userLogged = JSON.parse(localStorage.getItem('userData'))
        var userClient = clients.value.find(client => client._id == userLogged.clients)
        if (userClient) {
          clientCurrencies.value = userClient.currencies
        }
      })

    const currencies = ref([])
    store.dispatch('app-whitelabel/fetchCurrencies')
      .then(response => { 
        currencies.value = response.data
      })

    const blankWalletData = {
      amount: null,
      reference: null,
      currency: '',
      details: {
        description: '',
        type: '',
      },
      walletId: '',
      balanceUserSelected: null,
      balanceUserLogged: null
    }

    const walletData = ref(JSON.parse(JSON.stringify(blankWalletData)))
    const resetWalletData = () => {
      walletData.value = JSON.parse(JSON.stringify(blankWalletData))
    }
    
    const onSubmit = () => {
      walletData.value.walletId = props.userData.details.walletId // id del user receptor
      walletData.value.currency = walletData.value.currencies
      console.log('walletdata props: ', walletData.value.walletId)
     
     // actualizo wallet del usuario receptor
     console.log("data del user receptor: ", walletData.value)
      store.dispatch('app-user/transactionCreditDebit', walletData.value)
        .then( response => { 
          // create debit or credit to the logged user in order to balance the transaction
          const userLogged = JSON.parse(localStorage.getItem('userData'))
          walletData.value.walletId = userLogged.details.walletId // cambio el is al usuario loggeado
          if (walletData.value.details.type == 'debit') {
            walletData.value.details.type = 'credit'
          }else {
            walletData.value.details.type = 'debit'
          }  
          walletData.value.reference = walletData.value.reference + '' + walletData.value.details.type

          // actualizar wallet del user logged
          console.log("data del user logged: ", walletData.value)
         
          store.dispatch('app-user/transactionCreditDebit', walletData.value)
            .then(() => {
              //this.$router.go(0)
            })
            .catch((error) => {
              console.log('transaction credit/debit userLogged: ', error)
            })
        })
        .catch((error) => {
           console.log('transaction credit/debit user selected: ', error)
        })
    }
 
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetWalletData)

    return {
      walletData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      currencies,
      wallets,
      props,
      clientCurrencies
    }
  },
  methods: {

    async getBalance({currency}) {
      if (currency) {
        const userLogged = JSON.parse(localStorage.getItem('userData'))

        const selectedUser = this.userData._id
        const loggedUser = userLogged._id
        if (selectedUser == loggedUser) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'XIcon',
              text: 'Select another user',
              variant: 'danger',
            }
          });
        }
        try{
          // look for wallet - user 
          await store.dispatch('app-user/fetchUserWallet', {currency, walletId: this.userData.details.walletId})
            .then(response => { 
              console.log("balance wallet user: ", response)
              this.walletData.balanceUserSelected = response.data.balance
              })
            .catch( () => {
              this.walletData.currency = ''
              this.walletData.amount = null
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error!',
                  icon: 'XIcon',
                  text: 'User does not have a wallet with that currency',
                  variant: 'danger',
                }
              });
            })

            // look for wallet - userLogged  
          const userLogged = JSON.parse(localStorage.getItem('userData'))         
          await store.dispatch('app-user/fetchUserWallet', {currency, walletId: userLogged.details.walletId})
            .then(response => { 
              console.log("balance wallet user logged: ", response)
              this.walletData.balanceUserLogged = response.data.balance
              })
            .catch( () => {
              this.walletData.currency = ''
              this.walletData.amount = null
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error!',
                  icon: 'XIcon',
                  text: 'You dont have a wallet with that currency',
                  variant: 'danger',
                }
              });
            }) 
        }catch(error){
          console.log(error);
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error!',
                icon: 'XIcon',
                text: error.message,
                variant: 'danger',
              }
          });
        }
      }
    },

    allowTransaction({amount}) {
      if (amount == 0) {
        this.walletData.amount = null
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error!',
                icon: 'XIcon',
                text: 'amount have to be > 0',
                variant: 'danger',
            }
          });
      }
      if (amount) {
        if (this.walletData.details.type == 'credit' && (amount > this.walletData.balanceUserLogged) ) {
          this.walletData.amount = null
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error!',
                icon: 'XIcon',
                text: 'Your balance is less than credit amount',
                variant: 'danger',
            }
          });
        }
        if (this.walletData.details.type == 'debit' && (amount > this.walletData.balanceUserSelected) ) {
          this.walletData.amount = null
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error!',
                icon: 'XIcon',
                text: 'User balance is less than debit amount',
                variant: 'danger',
            }
          });
        }
      }
    }
  },
 
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
