<template>
  <div>
    <b-modal
      v-model="modalShow"
      :title="`${$t('user-list.text.endSessionUser')} ${
        userData.userName ? userData.userName : ''
      }`"
      no-close-on-backdrop
      bg-variant="white"
      shadow
      backdrop
      centered
      size="md"
      @hidden="resetData"
    >
      <div class="d-flex flex-row">

        <div class='d-flex flex-column col-8'>

        <span class="" v-if="usersOnlineId.includes(userData._id)"
        >{{ $t('user-list.messages.userIsOnline') }}</span
        ><span v-else class="text-center">
          {{ $t('user-list.messages.userIsOffline') }}
        </span>

          <div v-if="usersOnlineId.includes(userData._id)"
               class='mt-1'>
            <!-- description -->
            <validation-provider
              #default="validationContext"
              name="Description"
              rules="required"
            >
              <b-form-group label-for="description">
                <template #label>{{ $t("labels.description") }} </template>
                <b-form-input
                  id="description"
                  v-model="userData.description"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
        </div>

        <div class="col-4 text-center">
          <b-avatar
            size="60"
            src=""
            button
            badge
            badge-offset="-0.1em"
            variant="primary"
            :badge-variant="
              usersOnlineId.includes(userData._id) ? 'success' : 'danger'
            "
            class="text-center mb-1 badge-md"
          >
            <feather-icon icon="UserIcon" size="30" />
          </b-avatar>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <div class="d-flex">
            <b-button
              v-if="usersOnlineId.includes(userData._id)"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              :disabled="loadings.modal || loadings.send || !userData.description"
              @click="handleOk"
            >
              <b-spinner
                v-if="loadings.send"
                type="grow"
                label="Spinning"
                class="mr-1"
                small
              ></b-spinner>
              <feather-icon v-else icon="UserMinusIcon" class="mr-1" />
              {{ $t('buttons.yes') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="setModal(false)"
            >
              <feather-icon icon="XIcon" class="mr-1" />
              {{ $t("buttons.cancel") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { computed, reactive, ref } from "@vue/composition-api";
import MoneyLimitation from "@/views/pages/limitation/moneyLimitation.vue";
import { BAvatar, BButton, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import store from "@/store";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  name: "UserForceLogOut",
  components: {
    BFormInput, BFormInvalidFeedback, BFormGroup,
    ValidationProvider, BAvatar, BButton, MoneyLimitation, vSelect },
  directives: {
    Ripple,
  },
  setup() {
    const modalShow = ref(false);

    const userData = ref({});
    const loadings = reactive({
      send: false,
      modal: false,
    });

    const resetData = () => {
      userData.value = {};
    };

    const usersOnlineId = computed(() => {
      return store.state.socketIoStore.usersOnlineId;
    });

    const setModal = async (flag, data) => {
      if (flag) {
        userData.value = Object.assign({}, data);
        modalShow.value = true;
      } else {
        modalShow.value = false;
      }
    };

    const handleOk = async () => {
      try {
        loadings.send = true;
        const payload = {
          userId: userData.value._id,
          reason : userData.value.description
        };
        const simulateRequest = await store.dispatch(
          "app-user/forceUserLogout",
          payload
        );
        resetForm()
      } catch (error) {
        console.log(error);
      } finally {
        loadings.send = false;
      }
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    return {
      modalShow,
      userData,
      setModal,
      handleOk,
      loadings,
      resetData,
      usersOnlineId,
      refFormObserver,
      getValidationState,
      resetForm
    };
  },
};
</script>

<style scoped></style>
