<template>
    <div id="voucher">
        <!--<b-button v-b-modal.modal-1>V</b-button>-->
        <b-modal
            v-model="modalShow" 
            id="modal-1"
            size="md"
            button-size="sm"
            centered hide-footer hide-header
        >
            <b-container>
                <b-row class="m-1">
                    <!--<b-img rounded center src="https://picsum.photos/200/100?random=1" alt="Center image"></b-img>-->
                    <b-img class="imgMax" alt="Logo WhiteLabel" center :src="whitelabelSelectNavbar.logo"></b-img>
                </b-row>
                <b-row>
                    <b-col><b class="">Ticket number</b></b-col>
                    <b-col class="text-right small"><b class="bg-secondary text-white "># {{userSelect2.ticketNumber}}</b></b-col>
                </b-row>
                <b-row>  
                    <b-col><b class="">Date</b></b-col>
                    <b-col class="text-right small">{{ userSelect2.date }}</b-col>
                </b-row>
                <b-row>
                    <b-col><b class="">Ticket Office</b></b-col>
                    <b-col class="text-right">{{userSelect2.userNameOrigin}}</b-col>
                </b-row>
                <b-row>
                    <b-col><b class="">User</b></b-col>
                    <b-col class="text-right">{{userSelect2.userNameDestination}}</b-col>
                </b-row>
                <b-row class="text-center m-1" >
                    <b-col cols="12"><h3 class="text-uppercase">{{userSelect2.typetTansaction}}</h3></b-col>
                    <b-col cols="12"><h3><b class="bg-secondary text-white px-1">{{userSelect2.amount | currency({ symbol: currencySelectNavbar }) }}</b></h3></b-col>
                </b-row>
                <b-row class="text-center" id="botonFooter">
                    <b-col>
                        <b-button variant="primary" pill onclick="window.print()">Print</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="outline-secondary" pill @click="$bvModal.hide('modal-1')">Ok</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
    import {
    BContainer, BRow, BCol, BFormRow, BModal, BCard, BImg, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
    } from 'bootstrap-vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import store from '@/store';
    import { required } from '@validations';

    export default {
        components: {
            BContainer, BRow, BCol, BFormRow,
            BModal,
            BCard,
            BImg,
            BButton,
            BCardTitle,
            BCardText,
            BForm,
            BFormGroup,
            BInputGroup,
            BLink,
            BFormInput,
            BInputGroupAppend,
            ValidationProvider,
            ValidationObserver,
        },
        data() {
            return {
                modalShow: false,
                idUserSelect: '',
                userSelect2: 'null',
                // date: new Date(),
                type:'',
                // ticket: Math.floor(Math.random() * 9900000),
            }
        },
        computed: {
            currencySelectNavbar() {
            return this.$store.state.whitelabelCurrencyNabvar.currency;
            },
            whitelabelSelectNavbar() {
            return this.$store.state.whitelabelCurrencyNabvar.whitelabel;
            },
        },
        methods:{
            showModal(payload){
                this.modalShow = true
                this.userSelect2 = payload
            },
            hideModal(){
                this.modalShow = false
            },
        },

    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

code {
    font-size: 110%;
    color: #180d68;
}

[dir] .btn-primary {
    border-color: #e5710a !important;
    background-color: #e5710a !important;
}
.imgMax{
    max-height: 130px;
    max-width: 220px;
}
//Media Print
@media print{
    nav, footer, .main-menu, .content-body, #botonFooter{
        display: none;
    }
    
    .modal-content {
        border: 2px dotted rgb(50, 50, 250) !important;
        width: 13cm !important;
    }
    .row{
        max-width: 12cm;
    }
    .col {
        flex-basis: 0 !important;
        flex-grow: 1 !important;
    }
    


}







</style>
