<template>
  <div>
    <b-modal
      v-model="modalShow"
      centered
      size="lg"
      :title="`${$t('user-list.text.lockGamesAndProductsofUser')} ${user.userName}`"
      hide-footer
    >
      <b-overlay :show="!ValidInfo" variant="transparent" no-wrap />
      <b-card
        id="nav-scroller"
        ref="content"
        style="position: relative; height: 300px; overflow-y: scroll"
        no-body
        v-if="ValidInfo"
      >
      <!-- LISTA DE PRODUCTOS -->
        <b-list-group v-if="!productSelectId">
          <b-list-group-item active class="d-flex align-items-center">
            <span>{{ $t('user-list.text.selectOrLockProduct')}}</span>
          </b-list-group-item>
          <div
            class="m-2 text-center"
            v-if="productsOptions && !productsOptions.length >= 1"
          >
            <h4>{{ $t('user-list.text.theUser') + user.userName + $t('user-list.text.notHasProducts')}}</h4>
          </div>
          <b-list-group-item
            v-else
            v-for="(tr, index) in productsOptions"
            :key="index"
            class="align-items-center cursor-pointer"
          >
            <!-- <b-overlay :show="tr.loading" variant="transparent" no-wrap /> -->
            <b-row>
              <b-col>
                <b-img :src="tr.product.url_image" width="40" height="40" style="object-fit: contain"></b-img>
              </b-col>
              <b-col @click="selectProduct(tr.product)">
                <span class="text-primary">{{ tr.product.brand }}</span>
              </b-col>
              <b-col>
                <div class="d-flex justify-content-center">
                  <b-badge
                    pill
                    :variant="`light-primary`"
                    class="text-capitalize"
                  >
                    {{`${tr.product.totalGames} ${$t('user-list.text.games')}` }}
                  </b-badge>
                </div>
              </b-col>
              <b-col class="d-flex justify-content-end">
                <b-button
                  @click="showModalExcludeIncludesUsers(tr, 'product')"
                  variant="outline-primary"
                  size="sm"
                  class="mr-2 btn-icon rounded-circle"
                  v-b-tooltip.hover.top="'Usuarios excluidos'"
                  v-if="user.typeUser !== 'Player' && tr.locked"
                >
                  <feather-icon icon="AlignCenterIcon" size="16" />
                </b-button>
                <b-form-checkbox
                  class="custom-control-danger"
                  name="check-button"
                  switch
                  v-model="tr.locked"
                  @change="confirmExcludeUsers(tr, 'product')"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="LockIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="UnlockIcon" />
                  </span>
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>

        <!-- LISTA DE JUEGOS -->
        <b-list-group v-if="productSelectId">
          <b-overlay :show="loadingDataGames" variant="transparent" no-wrap />
          <b-list-group-item
            active
            class="d-flex justify-content-between align-items-center"
          >
            <div>
              <b-button
                variant="light"
                class="mr-1 btn-icon rounded-circle"
                size="sm"
                @click="productSelectId = ''"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
              <span class="text-bold">{{ brandName }} {{ $t('user-list.text.games') }} </span>
            </div>

            <div class="mr-1">{{ $t('message.tableHeader.status') }}</div>
          </b-list-group-item>

          <b-list-group-item>
            <!-- searh -->
            <b-form-input
              v-model="searchGames"
              :placeholder="$t('user-list.text.searchGames')"
            ></b-form-input>
          </b-list-group-item>

          <b-list-group-item
            v-for="(tr, index) in gamesProductSelectOptions"
            :key="index"
          >
          <b-row>
            <b-col  class="d-flex justify-content-start">
              <span>{{ tr.gameName }}</span>
            </b-col>
            <b-col  class="d-flex justify-content-end">
              <b-button
                @click="showModalExcludeIncludesUsers(tr, 'game')"
                variant="outline-primary"
                size="sm"
                class="mr-2 btn-icon rounded-circle"
                v-b-tooltip.hover.top="`${$t('user-list.text.usersExcludes')}`"
                v-if="user.typeUser !== 'Player'"
              >
                <feather-icon icon="AlignCenterIcon" size="16" />
              </b-button>
              <b-form-checkbox
                class="custom-control-danger"
                name="check-button"
                switch
                v-model="tr.locked"
                @change="confirmExcludeUsers(tr, 'game')"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="LockIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="UnlockIcon" />
                </span>
              </b-form-checkbox>              
            </b-col>
          </b-row>            
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-modal>

  <!-- Modal si desea excluir users hijos -->
    <b-modal
      v-model="showModalExcludeUsers"
      centered
      size="sm"
      :title="$t('user-list.text.selectUserToExclude')"
      hide-footer
      no-close-on-backdrop
      hide-header-close
    >
      <b-card>
        <b-row>
          <b-col md="12">
            <b-form-group label="Player Name" label-for="username">
              <template #label>{{ $t("labels.name_player") }}</template>
              <v-select
                @option:selected="selectUser"
                :options="usersOptionsComputed"
                :filterable="true"
                :clearable="false"
                label="userName"
                v-model="userSelect"
              >
                <template slot="no-options">
                  {{ $t("does_not_have_users") }}
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.userName }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-list-group v-if="usersExclude.length > 0" class="mt-2">
              <b-list-group-item
                v-for="(tr, index) in usersExclude"
                :key="index"
                class="d-flex justify-content-between align-items-center"
              >
                <span>{{ tr.userName }}</span>
                <feather-icon icon="XIcon" class="cursor-pointer" @click="quitUserExclude(index)"/>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-card>
      <!-- Form Actions -->
      <div class="d-flex justify-content-end mt-2">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          class="mr-2"
          @click="skipExceptUsers()"
          size="sm"
        >
          <feather-icon icon="ChevronsRightIcon" />
          {{ $t("buttons.skip") }}
        </b-button>

        <b-button
          @click="runLock()"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
        >
          <feather-icon icon="CheckIcon" />
          {{ $t("buttons.run") }}
        </b-button>
      </div>
    </b-modal>

    <!-- user excluidos incluidos -->
    <b-modal
      v-model="showModalExcludeIncludeUsers"
      centered
      size="sm"
      :title="$t('user-list.text.usersExcludes')"
      hide-footer
      no-close-on-backdrop
      hide-header-close
    >
      <b-card>
        <b-row>
          <b-col md="12">
            <b-overlay v-if="loadingDataUserExcludes" :show="loadingDataUserExcludes" variant="transparent" no-wrap />
            <div v-else>
              <b-overlay  :show="loadingExcludeIncludeUsersTwo" variant="transparent" no-wrap />
              <b-list-group v-if="usersExcludeIncludes.length > 0" class="mt-2">
                <b-list-group-item
                  v-for="(tr, index) in usersExcludeIncludes"
                  :key="index"
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>{{ tr.userName }}</span>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    @click="quitUserExcludeIncludes(tr, typeUserExcludes, itemSelectUserExclude)"
                  >
                    <feather-icon icon="XIcon" class="cursor-pointer"/>
                  </b-button>
                  
                </b-list-group-item>
              </b-list-group>
              <h4 class="text-center"  v-else>No hay usuarios...</h4>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <!-- Form Actions -->
      <div class="d-flex justify-content-end mt-2">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          class="mr-2"
          @click="showModalExcludeIncludeUsers = false"
          size="sm"
        >
          <feather-icon icon="ChevronsLeftIcon" />
          {{ $t("buttons.quit") }}
        </b-button>
      </div>
    </b-modal>

    
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  VBModal,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BCard,
  BOverlay,
  BBadge,
  BFormInput,
  BFormGroup
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { translatableText } from '@/@core/utils/utils';
import { axiosErrorHandle } from '@core/utils/errorHandler'

export default {
  components: {
    BButton,
    BModal,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BCard,
    BOverlay,
    BBadge,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    modalShow: false,
    user: {},
    productsOptions : [],
    searchQueryProduct: "",
    loadingDataGames: false,
    gamesProductSelect: [],
    productSelectId: "",
    searchGames: "",
    productName: "",
    brandName: "",
    ValidInfo: false,
    gamesLocks: [],
    usersOptions: [],
    usersExclude: [],
    userSelect: "",
    payload: {
      status: true,
      product: "",
      gameId: "",
      except: true,
      exclude: [],
      option: "",
    },
    showModalExcludeIncludeUsers: false,
    showModalExcludeUsers: false,
    usersExcludeIncludes: [],
    itemSelectUserExclude: {},
    typeUserExcludes: "",
    loadingDataUserExcludes: false,
    loadingExcludeIncludeUsersTwo: false,
    timeOut: null,
  }),
  watch: {
    modalShow(val) {
      if (!val) {
        this.productSelectId = "";
        this.searchGames = "";
        this.user = {};
        this.gamesLocks = [];
        this.ValidInfo = false;
      } else {
        this.getProductsGamesLocksUser();
        this.getUserProductsInfo()
      }
    },
  },
  computed: {
    gamesProductSelectOptions() {
      return this.gamesProductSelect.filter((game) => {
        return game.gameName
          .toLowerCase()
          .includes(this.searchGames.toLowerCase());
      });
    },
    whitelabelSelect(){
      return  this.$store.state.whitelabelCurrencyNabvar.whitelabel
    },
    currencieSelect(){
      return  this.$store.state.whitelabelCurrencyNabvar.currency
    },
    usersOptionsComputed() {
      return this.usersOptions.filter((user) => {
        return !this.usersExclude.some((userExclude) => {
          return userExclude._id === user._id;
        });       
      });      
    },
  },

  methods: {
    async confirmExcludeUsers({ _id, product, gameId, locked }, option) {
      if (!locked || this.user.typeUser !== 'Agent') {
     
        this.blockProductOrGameUser({ _id, gameId : _id, product, locked }, option);
        return;
      }

      //  pregustar si esta quiere excluir algun usuario
      const confirme = await this.$swal({
        title: this.$t("want_to_exclude_users"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("buttons.yes"),
        cancelButtonText: this.$t("buttons.no"),
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger ml-1",
        },
      });
      // si esta seguro se ejecuta la transaccion
      if (confirme.value) {
        this.showModalExcludeUsers = true;
        this.payload = {
          locked: locked,
          _id: _id,
          gameId: gameId,
          except: true,
          exclude: [],
          option: option,
        };
        this.onSearchUser()
      } else {
        console.log("No quiero excluir")
        this.blockProductOrGameUser({ _id, product, gameId, locked }, option);
      }
    },
    runLock(){
      this.payload.exclude = this.usersExclude.map((user) => {
        return user._id;
      });
      this.blockProductOrGameUser(this.payload, this.payload.option);
    },
    skipExceptUsers() {
      const obj = { 
        _id : this.payload._id, 
        gameId: this.payload.gameId,
        locked : this.payload.locked,
       }
      this.blockProductOrGameUser(obj, this.payload.option);
    },
    async blockProductOrGameUser({ _id, product, gameId, locked, exclude, except }, option) {
      const obj = {
        userId: this.user._id,
        product: option == "product" ? product : null, // se llena en caso de que sea un producto
        gameId: option == "game" ? gameId : null, // se llena en caso de que sea un juego
        status: locked,
        option: option,
      };
      //false para bloqueo
      //console.log("Para saber lock",locked);
      try {
        const response = await this.$store.dispatch("user/blockProductOrGameUser", obj);
        if (obj.option === "product") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${
                locked ? this.$t('user-list.text.lockProduct') : this.$t('user-list.text.unlockProduct')
              }`,
              icon: `${locked ? "LockIcon" : "UnlockIcon"}`,
              variant: `${locked ? "success" : "success"}`,
            },
          });
        } else if (option === "game") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${locked ? this.$t('user-list.text.lockGame') : this.$t('user-list.text.unlockGame')}`,
              icon: `${locked ? "LockIcon" : "UnlockIcon"}`,
              variant: `${locked ? "success" : "success"}`,
            },
          });
        }
        this.usersOptions = []
        this.usersExclude = []
        this.showModalExcludeUsers = false;
      } catch (error) {
        console.log(error);
        // validar si el error es 403
        if (error.response.status == 403) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: await translatableText({text: axiosErrorHandle(error)}),
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: this.$t('messages.unknown_error'),
            },
          });
        }
      } finally {
        // tr.loading = false
      }
    },
    selectProduct({ _id, name, isgame, brand }) {
      if (!isgame) {
        this.productSelectId = _id;
        this.productName = name;
        this.brandName = brand;
        this.getGamesProduct(brand,this.whitelabelSelect._id);
      }
    },
    async getGamesProduct(brand,wl) {
      try {
        this.loadingDataGames = true;
        const gamesLock = await this.$store.dispatch(
          "user/getProductsGamesLocksUser",
          this.user._id
        );
        this.gamesLocks = gamesLock.restrictedGames;
        const params = {
          idBrand: brand,
          idWhiteLabel: wl
        }
        let data = await this.$store.dispatch(
          "user/getGamesByBrand",
          params
        );
        data.forEach((game) => {
          const findIndex = this.gamesLocks.findIndex(
            (gameLock) => gameLock === game.game._id
          );
          if (findIndex !== -1) {
            game.game.locked = true;
          } else {
            game.game.locked = false;
          }
        });
        this.gamesProductSelect = data.map(obj=> obj.game);
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching games list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: await translatableText({text: axiosErrorHandle(error)}),
          },
        });
      } finally {
        this.loadingDataGames = false;
      }
    },
    async getProductsGamesLocksUser() {
      try {
        const data = await this.$store.dispatch(
          "user/getProductsGamesLocksUser",
          this.user._id
        );
        this.ValidInfo = true;
        // this.user.products.forEach((product) => {
        //   const findIndex = data.restrictedProducts.findIndex(
        //     (p) => p == product._id
        //   );
        //   if (findIndex != -1) {
        //     product.locked = true;
        //   } else {
        //     product.locked = false;
        //   }
        // });
          this.gamesLocks = data.restrictedGames;
      } catch (error) {
        console.log(error);
        this.modalShow = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: await translatableText({text: axiosErrorHandle(error)}),
          },
        });
      } finally {
      }
    },

    async getUserProductsInfo(){
      try {
        const data = await this.$store.dispatch(
            "user/getUserProductsInfo",
            this.user._id
        );
        this.ValidInfo = true;
        this.productsOptions = data
        console.log("Productosssss",this.productsOptions);
      } catch (error) {
        console.log(error);
        this.modalShow = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: await translatableText({text: axiosErrorHandle(error)}),
          },
        });
      } finally {
      }
    },

    selectUser(user){
      this.usersExclude.push(user)
      this.userSelect = ""
    },
    async onSearchUser(search, loading){
      // if(search.length){

        const data = {
          id: this.user._id,
          whitelabel:  this.whitelabelSelect._id,
          currency: this.currencieSelect,
        }

        // loading(true);
        if(this.timeOut) clearTimeout(this.timeOut)
        this.timeOut = setTimeout(() => {
          this.$store.dispatch("app-user/fetchUsersByNameForBranch", data).then((response) => {
            this.usersOptions = response.data.tree;
            // loading(false);
          });
        }, 500) 
      // }  
    },
    quitUserExclude(index){
      this.usersExclude.splice(index, 1)
    },
    async showModalExcludeIncludesUsers(item, type){
      try{
        this.showModalExcludeIncludeUsers = true;
        this.usersExcludeIncludes = [];
        this.loadingDataUserExcludes = true;
        this.itemSelectUserExclude = item;
        this.typeUserExcludes = type;

        if(type == "product"){
          this.usersExcludeIncludes = await this.$store.dispatch("app-user/getUsersExcludeIncludes", {
            id: this.user._id,
            option: 1,
            status: true,
            productId: item.productId,
          })
          }else{
            this.usersExcludeIncludes = await this.$store.dispatch("app-user/getUsersExcludeIncludes", {
              id: this.user._id,
              option: 2,
              status: true,
              gameId: item.gameId,
            })
          }
      }catch(error){
        console.log(error)
      }finally{
        this.loadingDataUserExcludes = false;
      }

    },
    async quitUserExcludeIncludes(user, type, item){
      try{
        const confirme = await this.$swal({
          title: this.$t("are_your_sure"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("buttons.yes"),
          cancelButtonText: this.$t("buttons.no"),
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary mr-1",
            cancelButton: "btn btn-danger ml-1",
          },
        });

        if(confirme.value){
          this.loadingExcludeIncludeUsersTwo = true;
          const obj = {
                  userId: user._id,
                  product: type == "product" ? item._id : null, // se llena en caso de que sea un producto
                  gameId: type == "game" ? item.gameId : null, // se llena en caso de que sea un juego
                  status: false,
                  option: type,
                };
          await this.$store.dispatch("user/blockProductOrGameUser", obj);  
          this.$toast({
              component: ToastificationContent,
              props: {
                title: "Exitoso",
                icon: "CheckListIcon",
                variant: "success",
              },
            });

            this.usersExcludeIncludes = await this.$store.dispatch("app-user/getUsersExcludeIncludes", {
              id: this.user._id,
              option: type == 'product' ? 1 : 2,
              status: true,
              gameId: type == 'game' ? item.gameId : null,
              productId: type == 'product' ? item.productId : null,
            })
        }
      }catch(error){
        console.log(error)
      }finally{
        this.loadingExcludeIncludeUsersTwo = false;
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>