import axios from "@axios";
const APP_URL = process.env.VUE_APP_URL;
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createUserLimitation(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APP_URL}/auto-limitacion/user`, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getUserLimitation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/auto-limitacion/users/`, { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    setUserLimitation(ctx, { id, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${APP_URL}/auto-limitacion/${id}`, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
