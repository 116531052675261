<template>
  <b-modal
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    :title="
      modeEdit ? $t('tabs.edituser') + userData.userName : $t('tabs.addnewuser')
    "
    shadow
    backdrop
    no-close-on-backdrop
    hide-footer
    size="lg"
    @change="(val) => ShowSidebar(val)"
  >
    <template #default="{ hide }">
      <div>
        <b-tabs v-model="tabIndex" pills>
          <b-tab v-if="!modeEdit || userData.typeUser == 'Add New User'" active>
            <!-- Btabs -->
            <template #title>{{ $t("tabs.player") }} </template>
            <div>
              <b-tabs content-class="mt-3">
                <b-tab active>
                  <template #title>{{ $t("tabs.login") }} </template>
                  <!-- username -->
                  <validation-provider
                    #default="validationContext"
                    name="User name"
                    rules="required"
                  >
                    <b-form-group label-for="username">
                      <template #label>{{ $t("labels.username") }} </template>
                      <b-form-input
                        id="username"
                        v-model="userData.userName"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <!-- Password -->
                  <b-form-group>
                    <label for="password">{{ $t("labels.password") }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                      v-if="!modeEdit"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="password"
                          v-model="userData.password"
                          :type="passwordFieldType"
                          class="form-control-merge"
                          :state="errors.length > 0 ? false : null"
                          name="password"
                        />

                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Confirm Password -->
                  <b-form-group>
                    <label for="password">{{
                      $t("labels.confirPassword")
                    }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="ConfirmPassword"
                      rules="required|confirmed:Password"
                      v-if="!modeEdit"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="confirmpassword"
                          v-model="userData.confirmpassword"
                          :type="passwordFieldType"
                          class="form-control-merge"
                          :state="errors.length > 0 ? false : null"
                          name="password"
                        />

                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-tab>
                <b-tab>
                  <template #title> {{ $t("tabs.information") }} </template>
                  <!-- firstname -->
                  <validation-provider
                    #default="validationContext"
                    name="First Name"
                    rules=""
                  >
                    <b-form-group label-for="firstname">
                      <template #label>{{ $t("labels.firstname") }} </template>
                      <b-form-input
                        id="firstname"
                        v-model="userData.firstName"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="John Doe"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- lastname -->
                  <validation-provider
                    #default="validationContext"
                    name="Last Name"
                    rules=""
                  >
                    <b-form-group label-for="lastname">
                      <template #label>{{ $t("labels.lastname") }} </template>
                      <b-form-input
                        id="lastname"
                        v-model="userData.lastName"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="John Doe"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Email -->
                  <validation-provider
                    #default="validationContext"
                    name="Email"
                    rules="email"
                  >
                    <b-form-group label-for="email">
                      <template #label>{{ $t("labels.email") }} </template>
                      <b-form-input
                        id="email"
                        v-model="userData.email"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  
                  <!-- Address -->
                  <b-form-group label-for="address">
                      <template #label>{{ $t("labels.address") }} </template>
                      <b-form-input
                        id="address"
                        v-model="userData.address"
                        trim
                        placeholder=""
                      />
                  </b-form-group>
                  <!-- Phone -->
                  <b-form-group label-for="phone">
                      <template #label>{{ $t("labels.phone") }} </template>
                      <b-form-input
                        id="phone"
                        v-model="userData.phone"
                        trim
                        placeholder=""
                      />
                  </b-form-group>
                  <!-- Country -->
                  <b-form-group label-for="country">
                      <template #label>{{ $t("labels.country_of_residence") }} </template>
                      <b-form-input
                        id="country"
                        v-model="userData.country"
                        trim
                        placeholder=""
                      />
                  </b-form-group>

                </b-tab>
                <b-tab>
                  <template #title> {{ $t("tabs.permissions") }} </template>

                  <!-- whitelabel -->
                  <validation-provider
                    #default="validationContext"
                    name="whitelabel"
                    rules="required"
                  >
                    <b-form-group label-for="whitelabel">
                      <template #label>{{ $t("labels.whitelabel") }} </template>
                      <v-select
                        v-model="userData.whitelabel"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="whitelabelOptions"
                        :reduce="(e) => e._id"
                        :clearable="false"
                        :disabled="true"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Client -->
                  <!-- <validation-provider
                    #default="validationContext"
                    name="Client"
                    rules="required"
                    v-if="userLogged.typeUser == 'Root'"
                  >
                    <b-form-group
                      label="Client"
                      label-for="client"
                    >
                      <v-select
                        v-model="userData.client"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="clientsOptions"
                        @option:selected="selectedClient"
                        :reduce="(e) => e._id"
                        :clearable="false"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>

                    </b-form-group>
                  </validation-provider> -->

                  <!-- Currencies -->
                  <validation-provider
                    #default="validationContext"
                    name="Currencies"
                    rules="required"
                  >
                    <b-form-group label="Currencies" label-for="currencie">
                      <template #label>{{ $t("labels.currencies") }} </template>
                      <v-select
                        v-model="userData.currencies"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        multiple
                        :options="currenciesOptions"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <!-- Products -->
                  <validation-provider
                    #default="validationContext"
                    name="Products"
                    rules="required"
                  >
                    <b-form-group label-for="products">
                      <template #label>{{ $t("labels.products") }} </template>
                      <v-select
                        v-model="userData.products"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        multiple
                        :options="productsOptions"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-tab>
              </b-tabs>
            </div>
          </b-tab>

          <b-tab
            v-if="
              !modeEdit ||
              userData.typeUser == 'Agent' ||
              userData.typeUser == 'Admin'
            "
          >
            <template #title> {{ $t("tabs.agent") }} </template>
            <!-- Btabs -->
            <div>
              <b-tabs content-class="mt-3">
                <b-tab active>
                  <template #title> {{ $t("tabs.login") }} </template>
                  <!-- username -->
                  <validation-provider
                    #default="validationContext"
                    name="User name"
                    rules="required"
                  >
                    <b-form-group label-for="username">
                      <template #label> {{ $t("labels.username") }} </template>
                      <b-form-input
                        id="username"
                        v-model="userData.userName"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <!-- Password -->
                  <b-form-group>
                    <label for="password">{{ $t("labels.password") }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                      v-if="!modeEdit"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="password"
                          v-model="userData.password"
                          :type="passwordFieldType"
                          class="form-control-merge"
                          :state="errors.length > 0 ? false : null"
                          name="password"
                        />

                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Confirm Password -->
                  <b-form-group>
                    <label for="password">{{
                      $t("labels.confirPassword")
                    }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="ConfirmPassword"
                      rules="required|confirmed:Password"
                      v-if="!modeEdit"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="confirmpassword"
                          v-model="userData.confirmpassword"
                          :type="passwordFieldType"
                          class="form-control-merge"
                          :state="errors.length > 0 ? false : null"
                          name="password"
                        />

                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-tab>
                <b-tab>
                  <template #title> {{ $t("tabs.information") }} </template>
                  <!-- firstname -->
                  <validation-provider
                    #default="validationContext"
                    name="First Name"
                    rules=""
                  >
                    <b-form-group label="First Name" label-for="firstname">
                      <template #label> {{ $t("labels.firstname") }} </template>
                      <b-form-input
                        id="firstname"
                        v-model="userData.firstName"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="John Doe"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- lastname -->
                  <validation-provider
                    #default="validationContext"
                    name="Last Name"
                    rules=""
                  >
                    <b-form-group label-for="lastname">
                      <template #label> {{ $t("labels.lastname") }} </template>
                      <b-form-input
                        id="lastname"
                        v-model="userData.lastName"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="John Doe"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Email -->
                  <validation-provider
                    #default="validationContext"
                    name="Email"
                    rules="email"
                  >
                    <b-form-group label-for="email">
                      <template #label> {{ $t("labels.email") }} </template>
                      <b-form-input
                        id="email"
                        v-model="userData.email"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <!-- Address -->
                  <b-form-group label-for="address">
                      <template #label>{{ $t("labels.address") }} </template>
                      <b-form-input
                        id="address"
                        v-model="userData.address"
                        trim
                        placeholder=""
                      />
                  </b-form-group>
                  <!-- Phone -->
                  <b-form-group label-for="phone">
                      <template #label>{{ $t("labels.phone") }} </template>
                      <b-form-input
                        id="phone"
                        v-model="userData.phone"
                        trim
                        placeholder=""
                      />
                  </b-form-group>
                  <!-- Country -->
                  <b-form-group label-for="country">
                      <template #label>{{ $t("labels.country_of_residence") }} </template>
                      <b-form-input
                        id="country"
                        v-model="userData.country"
                        trim
                        placeholder=""
                      />
                  </b-form-group>
                </b-tab>
                <b-tab>
                  <template #title> {{ $t("tabs.permissions") }} </template>
                  <!-- whitelabel -->
                  <validation-provider
                    #default="validationContext"
                    name="whitelabel"
                    rules="required"
                  >
                    <b-form-group label="Whitelabel" label-for="whitelabel">
                      <template #label>
                        {{ $t("labels.whitelabel") }}
                      </template>
                      <v-select
                        v-model="userData.whitelabel"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="whitelabelOptions"
                        :reduce="(e) => e._id"
                        :clearable="false"
                        :disabled="true"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Client -->
                  <!-- <validation-provider
                    #default="validationContext"
                    name="Client"
                    rules="required"
                    v-if="userLogged.typeUser == 'Root'"
                  >
                    <b-form-group
                      label="Client"
                      label-for="client"
                    >
                      <v-select
                        v-model="userData.client"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="clientsOptions"
                        @option:selected="selectedClient"
                        :reduce="(e) => e._id"
                        :clearable="false"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>

                    </b-form-group>
                  </validation-provider> -->

                  <!-- Currencies -->
                  <validation-provider
                    #default="validationContext"
                    name="Currencies"
                    rules="required"
                  >
                    <b-form-group label="Currencies" label-for="currencie">
                      <template #label>
                        {{ $t("labels.currencies") }}
                      </template>
                      <v-select
                        v-model="userData.currencies"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        multiple
                        :options="currenciesOptions"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Products -->
                  <validation-provider
                    #default="validationContext"
                    name="Products"
                    rules="required"
                  >
                    <b-form-group label-for="products">
                      <template #label> {{ $t("labels.products") }} </template>
                      <v-select
                        v-model="userData.products"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        multiple
                        :options="productsOptions"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Role -->
                  <validation-provider
                    #default="validationContext"
                    name="Role"
                    rules="required"
                    v-if="
                      userLogged.typeUser == 'Root' ||
                      userLogged.typeUser == 'Admin'
                    "
                  >
                    <b-form-group label-for="role">
                      <template #label> {{ $t("labels.roles") }} </template>
                      <v-select
                        v-model="userData.roles"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :reduce="(e) => e._id"
                        :options="rolesOptions"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-tab>
              </b-tabs>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          @click="onSubmit()"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          type="submit"
        >
          <feather-icon icon="SaveIcon" class="mr-1" />
          {{ $t("buttons.save") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          @click="hide"
        >
          <feather-icon icon="XIcon" class="mr-1" />
          {{ $t("buttons.cancel") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BTab,
  TabsPlugin,
  BTabs,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSelect,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, onUnmounted, watch, computed, getCurrentInstance } from "@vue/composition-api";
import { required, alphaNum, email, alphaDash } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import store from "@/store";
import { codeMultiple } from "../../../forms/form-element/form-select/code";
import roleStoreModule from "../../../pages/roles/roleStoreModule";
import clientStoreModule from "../../../pages/clients/clientStoreModule";
import userStoreModule from "../userStoreModule";
import whitelabelStoreModule from "../../../pages/whitelabel/whitelabelStoreModule";
import { useToast } from "vue-toastification/composition";
import ComponentAddPlayer from "./ComponentAddPlayer.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { translatableText } from '@core/utils/utils'
import { axiosErrorHandle } from '@core/utils/errorHandler'

export default {
  components: {
    BTab,
    TabsPlugin,
    BTabs,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ComponentAddPlayer,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  watch: {
    isAddNewUserSidebarActive(newVal) {
      if (!newVal) {
        this.modeEdit = false;
        this.userData = this.blankUserData;
      } else {
        this.getRoles();
      }
    },
  },
  data() {
    return {
      required,
      alphaNum,
      alphaDash,
      email,
      userIsAdmin: false,
      codeMultiple,
      name: "",
    };
  },
  computed: {
    userLoggedIsAdmin() {
      const userLogged = JSON.parse(localStorage.getItem("userData"));
      if (userLogged.is_admin == true) {
        return (this.userIsAdmin = true);
      } else {
        return (this.userIsAdmin = false);
      }
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  setup(props, { emit }) {
    const ROLE_APP_STORE_MODULE_NAME = "app-role";
    const CLIENT_APP_STORE_MODULE_NAME = "app-client";
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const MODULE_APP_STORE_MODULE_NAME = "app-whitelabel";
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME))
      store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule);
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME))
      store.registerModule(MODULE_APP_STORE_MODULE_NAME, whitelabelStoreModule);

    const rolesOptions = ref([]);
    const rolesAllowed = ref([]);
    const clientRoles = ref([]);
    const toast = useToast(ToastificationContent);
    const userLogged = ref(JSON.parse(localStorage.getItem("userData")));

    const clientsOptions = ref([]);
    const clientsAllowed = ref([]);
    const vm = getCurrentInstance()

    const getRoles = () => {
      store
        .dispatch("app-role/fetchRoles")
        .then((response) => {
          rolesOptions.value = response.data.roles;
        })
        .catch(async (error) => {
          toast({
            message: await translatableText({text: axiosErrorHandle(error)}),
            type: "is-danger",
            duration: 5000,
          });
        });
    };

    const users = ref([]);
    const usersForParent = ref([]);

    // store.dispatch('app-user/fetchUsersAll')
    //   .then(response => {
    //     var newArray = response.data.users.filter((item) => item.status == true )
    //     users.value = newArray
    //   })
    //   .then(() => {
    //     // Options for parents are only users related to logged user tree
    //     const userLogged = JSON.parse(localStorage.getItem('userData'))
    //     if (userLogged) {
    //       users.value.forEach(element => {
    //         if (element.parents) {
    //           var family = element.parents.find(user => user == userLogged._id)
    //           if (family) {
    //             usersForParent.value.push(element)
    //           }
    //         }
    //       });
    //     }
    // })

    const currencies = ref([]);
    // store.dispatch('app-whitelabel/fetchCurrencies')
    //   .then(response => {
    //     currencies.value = response.data
    //   })

    const blankUserData = {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      address: "",
      phone: "",
      country: "",
      password: "",
      confirmpassword: "",
      roles: "",
      rolesAllowed: [],
      userAdmin: false,
      clients: "",
      node: "x",
      children: [
        {
          node: "x",
          userId: "x",
        },
      ],
      players: [
        {
          userId: "",
          node: "",
        },
      ],
      level: 0,
      upLine: "",
      parents: [],
      currencies: [],
      parentSelected: "",
      clientId: "",
      whitelabelId: "",
    };

    const whitelabelOptions = ref([]);
    const currenciesOptions = ref([]);
    const productsOptions = ref([]);
    whitelabelOptions.value = JSON.parse(localStorage.getItem("whitelabels"));

    const userData = ref(blankUserData);
    const resetuserData = () => {
      userData.value = blankUserData;
    };

    const whitelabelSelect = computed(() => {
      return store.state.whitelabelCurrencyNabvar.whitelabel;
    });

    const setDataUtils = () => {
      userData.value.whitelabel = whitelabelSelect.value._id;

      if (userLogged.value.typeUser == "Root") {
        productsOptions.value = whitelabelSelect.value.products;
        userData.value.products = whitelabelSelect.value.products;

        currenciesOptions.value = whitelabelSelect.value.currencies;
        userData.value.currencies = whitelabelSelect.value.currencies;
      } else {
        productsOptions.value = userLogged.value.products;
        userData.value.products = userLogged.value.products;

        currenciesOptions.value = userLogged.value.currencies;
        userData.value.currencies = userLogged.value.currencies;
      }
    };
    setDataUtils();
    watch([whitelabelSelect], () => {
      setDataUtils();
    });

    const modeEdit = ref(false);
    const tabIndex = ref(0);

    // watch tabIndex
    watch([tabIndex], () => {
      console.log(tabIndex.value, "tabIndex")
    });

    const onSubmit = async () => {
      try {
        let datosUser = {
          userName: userData.value.userName,
          firstName: userData.value.firstName,
          lastName: userData.value.lastName,
          email: userData.value.email,
          address: userData.value.address,
          phone: userData.value.phone,
          country: userData.value.country,
          password: userData.value.password,
          client: whitelabelSelect.value.client,
          currencies: userData.value.currencies,
          roles: [userData.value.roles],
          platform: "admin",
          whitelabels: [whitelabelSelect.value._id],
          products: userData.value.products,
        };
        if (modeEdit.value) {
          datosUser._id = userData.value._id;
          const response = await store.dispatch(
            "app-user/updateUser",
            datosUser
          );
        } else {
          datosUser.typeUser = tabIndex.value ? "Agent" : "Player";
          const response = await store.dispatch("app-user/addUser", datosUser);
        }

        resetuserData();
        emit("refetch-data");
        emit("update:is-add-new-user-sidebar-active", false);

        if (modeEdit.value) {
          toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t('user-list.messages.userEdited'),
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t('user-list.messages.userCreated'),
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: await translatableText({text: axiosErrorHandle(error)}),
            icon: "XIcon",
            variant: "danger",
          },
        });
      }

      // If parent selected has value, become parent of the new user else, the user logged is the parent
      // try {

      //   if (userData.value.parentSelected){
      //     // Find user parent selected
      //     try{
      //       const response = await store.dispatch('app-user/fetchUser', { id: userData.value.parentSelected })
      //       if (response) {
      //         const userParent = response.data.data
      //         updateParentsDataWithParentSelected(userParent)
      //         await createNewUser(userParent)
      //       }
      //     } catch(error){
      //       throw error
      //     }
      //   }else {
      //     try{
      //       const userLogged = JSON.parse(localStorage.getItem('userData'))
      //       updateParentsDataWithUserLogged(userLogged)
      //       await createNewUser(userLogged)
      //     } catch (error){
      //       throw error
      //     }
      //   }

      //   resetuserData()
      //   toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'User Created',
      //       icon: 'EditIcon',
      //       variant: 'success',
      //     },
      //   })
      // } catch(error){
      //   console.error(error)
      //   toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: error.msg || 'error creating user',
      //       icon: 'EditIcon',
      //       variant: 'error',
      //     },
      //   })
      // }
    };

    const selectedClient = async (client) => {
      // userData.value.currencies = []
      // currenciesOptions.value = whitelabelSelect.value.currencies
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      tabIndex,
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      rolesOptions,
      clientsOptions,
      users,
      rolesAllowed,
      currencies,
      usersForParent,
      clientRoles,
      clientsAllowed,
      selectedClient,
      currenciesOptions,
      blankUserData,
      modeEdit,
      whitelabelOptions,
      userLogged,
      productsOptions,
      getRoles,
    };
  },
  methods: {
    ShowSidebar(val) {
      this.$emit("update:is-add-new-user-sidebar-active", val);
    },
    methoEditUser(userDataEdit) {
      this.modeEdit = true;
      // this.userData =
      this.userData = {
        userName: userDataEdit.userName,
        firstName: userDataEdit.firstName,
        lastName: userDataEdit.lastName,
        email: userDataEdit.email,
        address: userDataEdit.address,
        phone: userDataEdit.phone,
        country: userDataEdit.country,
        currencies: userDataEdit.currencies,
        roles: userDataEdit.roles[0]._id,
        client: userDataEdit.client,
        typeUser: userDataEdit.typeUser,
        _id: userDataEdit._id,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#add-new-user-sidebar___BV_modal_header_ {
  // background: linear-gradient(40deg, #e5710a, #f16029);
}

#add-new-user-sidebar___BV_modal_title_ {
  color: white !important;
}

.nav-tabs .nav-link.active {
  color: #001862;
}

.nav-pills .nav-link.active {
  background-color: #02cce0;
  border-color: #02cce0;
  box-shadow: 0 4px 18px -4px #001862;
}

.nav-tabs .nav-link:after {
  background: linear-gradient(30deg, #02cce0, #001862) !important;
}

// button[type="submit"] {
//   background-color: #e5710a !important;
//   border-color: #e5710a !important;
// }

// button[type="submit"]:hover {
//   box-shadow: 0 8px 25px -8px #f16029 !important;
// }

// button.btn.btn-outline-secondary,
// button.close {
//   border: 2px solid #e5710a !important;
//   color: #e5710a;
// }

// .modal-content {
//   width: 120% !important;
// }

[dir] .nav-pills li {
  width: 50%;
}
</style>
