var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":_vm.typeTransaction == 'credit'
        ? _vm.$t('tabs.credit') + _vm.userSelect.userName
        : _vm.$t('tabs.debit') + _vm.userSelect.userName,"centered":"","hide-footer":"","size":"sm"},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[(!_vm.userLoggedNotWallet)?_c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',{staticClass:"d-flex justify-content-center"},[(!_vm.validateWhitelabel())?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Amount to recharge"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.typeTransaction == "credit" ? _vm.$t("labels.amounttorecharge") : _vm.$t("labels.amounttodebit")))]},proxy:true}],null,false,3956871313)},[_c('validation-provider',{ref:"AmountInput",attrs:{"name":"amount","rules":"required|min:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.transaction.amount),callback:function ($$v) {_vm.$set(_vm.transaction, "amount", $$v)},expression:"transaction.amount"}},'money',{ prefix: _vm.currencySelect, masked: false },false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validWalletAmounTranferir && _vm.typeTransaction === 'credit' )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('user-list.messages.walletAmounTranferirError')))]):_vm._e(),(_vm.validWalletAmountDebit && _vm.typeTransaction === 'debit' )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('user-list.messages.walletAmountDebitError')))]):_vm._e()]}}],null,false,3346479124)})],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"description"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('labels.description')))]},proxy:true}],null,false,2539206298)},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","state":errors.length > 0 ? false:null,"name":"description","placeholder":_vm.$t('user-list.placeholder.transactionDescription')},model:{value:(_vm.transaction.description),callback:function ($$v) {_vm.$set(_vm.transaction, "description", $$v)},expression:"transaction.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2603202779)})],1)],1):_vm._e(),(!_vm.validateWhitelabel())?_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":_vm.typeTransaction == 'credit'
                    ? 'outline-success'
                    : 'outline-danger',"size":"sm"},on:{"click":function($event){return _vm.sumAmount({ amount: 100 })}}},[_c('feather-icon',{attrs:{"icon":_vm.typeTransaction == 'credit' ? 'PlusIcon' : 'MinusIcon',"size":"16"}}),_vm._v(" 100 ")],1),_c('b-button',{staticClass:"mr-1",attrs:{"variant":_vm.typeTransaction == 'credit'
                    ? 'outline-success'
                    : 'outline-danger',"size":"sm"},on:{"click":function($event){return _vm.sumAmount({ amount: 1000 })}}},[_c('feather-icon',{attrs:{"icon":_vm.typeTransaction == 'credit' ? 'PlusIcon' : 'MinusIcon',"size":"16"}}),_vm._v(" 1.000 ")],1),_c('b-button',{attrs:{"variant":_vm.typeTransaction == 'credit'
                    ? 'outline-success'
                    : 'outline-danger',"size":"sm"},on:{"click":function($event){return _vm.sumAmount({ amount: 10000 })}}},[_c('feather-icon',{attrs:{"icon":_vm.typeTransaction == 'credit' ? 'PlusIcon' : 'MinusIcon',"size":"16"}}),_vm._v(" 10.000 ")],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{staticClass:"mr-1",attrs:{"variant":"danger","show":_vm.validateWhitelabel()}},[_vm._v(_vm._s(_vm.$t('user-list.messages.validateDocuments')))])],1),_c('b-col',{staticClass:"mt-2 d-flex justify-content-end",attrs:{"cols":"12"}},[(!_vm.validateWhitelabel())?_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.confirmeTransaction.apply(null, arguments)}}},[_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.typeTransaction == "credit" ? _vm.$t("buttons.recharge") : _vm.$t("buttons.retirement"))+" ")],1):_vm._e()],1)],1)],1)],1)],1):_c('div',[_c('h5',[_vm._v(" "+_vm._s(_vm.$t('user-list.messages.userLoggedNotWallet'))+" ")])])]),_c('user-money-withdrawal-by-pin',{ref:"userMoneyWithdrawalByPin",on:{"fetch-user-data":function($event){return _vm.$emit('fetchUser')}}}),_c('UserVoucher',{ref:"UserVoucher"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }