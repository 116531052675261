<template>
  <div>
    <b-modal id="deleteModal" v-model="modalShow" :title="$t('Alert')" hide-footer>
      <span>{{ $t('user-list.messages.askDeleteUser') }}</span>
      <b-row class="mt-2" align-h="end">
        <b-button size="sm" class="mr-1" @click="$bvModal.hide('deleteModal')">{{ $t('buttons.cancel') }}</b-button>
        <b-button size="sm" variant="danger" @click="deleteAccount"><b-spinner v-if="showSpinner" small class="mr-1" label="Loading..."></b-spinner>{{ $t('buttons.accept') }}</b-button>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { BModal } from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "@axios";
import { translatableText } from '@/@core/utils/utils';
import { axiosErrorHandle } from '@core/utils/errorHandler'
export default {
  data() {
    return {
      modalShow: false,
      userId: null,
      showSpinner: false,
    };
  },
  methods: {
    setShowModal(userId) {
      this.modalShow = true;
      this.userId = userId;
    },
    showNotification(message, icon, variant) {
        // alert message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: icon,
            variant: variant,
          },
        })
      },
    deleteAccount() {      
      this.showSpinner = true;
      axios
        .delete(`${process.env.VUE_APP_URL}/destroyPlayer/${this.userId}`)
        .then(async (response) => {
          this.$emit("loadUsers");
          this.modalShow = false;
          this.showSpinner = false;
          this.showNotification(this.$t('user-list.messages.userRemoved'), "UserIcon", "primary");
        })
        .catch(async (error) => {
          this.showNotification(
            await translatableText({ text: axiosErrorHandle(error)}),
            "AlertCircleIcon",
            "danger"
          );
          this.showSpinner = false;
        });
    },
  },
};
</script>
