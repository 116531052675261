var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getLimitsError && !_vm.loadings.getLimitLoading)?_c('b-container',[_c('b-card',[_c('b-row',[_c('b-col',[_vm._v(" No se pudieron cargar tus límites, inténtalo de nuevo ")]),_c('b-col',[_c('b-button',{staticClass:"full-width",attrs:{"variant":"primary","disabled":_vm.loadings.setLimitLoading},on:{"click":function($event){return _vm.initComponent()}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"RotateCcwIcon"}}),_vm._v(" Reintentar ")],1)],1)],1)],1)],1):(_vm.loadings.getLimitLoading)?_c('b-container',[_c('b-row',_vm._l((_vm.userData.wallet.currencies),function(item,index){return _c('b-col',{key:index,attrs:{"md":"6"}},[_c('b-skeleton',{staticClass:"mt-1",attrs:{"animation":"fade","height":"35px"}})],1)}),1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"d-flex justify-content-center"},_vm._l((4),function(item,index){return _c('b-skeleton',{key:index,staticStyle:{"margin":"4px"},attrs:{"animation":"fade","width":"80px","height":"30px"}})}),1),_c('b-col',{attrs:{"md":"12"}},[_c('b-skeleton',{staticClass:"mt-1",attrs:{"animation":"fade","height":"30px"}})],1)],1)],1):_c('b-container',[_c('h4',{staticClass:"mb-1 d-flex justify-content-center"},[_vm._v(_vm._s(_vm.subtitle))]),(Array.isArray(_vm.userData.wallet.currencies))?_c('b-row',_vm._l((_vm.userData.wallet.currencies),function(currencyObject,index){return _c('b-col',{key:index,attrs:{"md":"6"}},[_c('input',{directives:[{name:"money",rawName:"v-money",value:({
          prefix: '' + currencyObject.currency + ' ',
          masked: false,
          precision: 2,
        }),expression:"{\n          prefix: '' + currencyObject.currency + ' ',\n          masked: false,\n          precision: 2,\n        }"}],key:_vm.key,staticClass:"mb-1 form-control",attrs:{"spellcheck":"false","disabled":_vm.loadings.setLimitLoading},domProps:{"value":_vm.amounts[index]},on:{"change":function($event){return _vm.setDataFromComponent(index, $event.target.value)}}})])}),1):_vm._e(),_c('b-row',[_c('b-col',_vm._l((_vm.limitSession),function(item,index){return _c('b-button',{key:index,staticStyle:{"margin":"5px"},attrs:{"size":"sm","disabled":_vm.loadings.setLimitLoading,"variant":_vm.currencyLimitation.durationType === item.valueType &&
          _vm.currencyLimitation.duration === item.value
            ? 'primary'
            : 'secondary'},on:{"click":function($event){return _vm.handleSetLimitDuration(item)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-button',{staticClass:"full-width",attrs:{"variant":"primary","disabled":(_vm.currencyLimitation.duration === 0 &&
            _vm.currencyLimitation.durationType === null) ||
          _vm.loadings.setLimitLoading},on:{"click":function($event){return _vm.handleChangeLimits()}}},[(_vm.loadings.setLimitLoading)?_c('b-spinner',{staticClass:"mr-1",attrs:{"type":"grow","label":"Spinning","small":""}}):_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"DollarSignIcon"}}),_vm._v(" Guardar ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }