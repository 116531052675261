<template>
  <div>
    <b-modal
      :visible="showWithdrawalByPinModal"
      bg-variant="white"
      :title="modalTittle"
      shadow
      backdrop
      no-close-on-backdrop
      :hide-header-close="loadings.componentLoading"
      hide-footer
      centered
      size="md"
      @hidden="setWithdrawalByPinModal(false)"
      @ok="setWithdrawalByPinModal(false)"
    >
      <template #default="{ hide }">
        <div>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-row>
              <b-col v-if="step === 0">
                <!-- PIN -->
                <validation-provider
                  #default="validationContext"
                  name="PIN"
                  rules="required"
                >
                  <b-form-group label-for="PIN">
                    <template #label>
                      {{ $t('user-list.labels.pinCode') }}
                    </template>
                    <b-form-input
                      v-model="userData.pinCode"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col v-if="step === 1" style="padding: 20px">
                <b-row>
                  <b-col class="text-center">
                    <b-avatar variant="secondary" size="45">
                      <feather-icon size="21" icon="InfoIcon" />
                    </b-avatar>
                  </b-col>
                  <b-col cols="10">
                    <p>
                      {{ loadingPingText }}
                    </p>
                  </b-col>
                </b-row>
              </b-col>

              <b-col v-if="step === 2">
                <b-row>
                  <b-col>
                    <div class="d-flex justify-content-center" style="gap: 5px">
                      <div>{{ $t('user-list.text.montoPin') }}</div>
                      <b-badge>{{ userData.withDrawlRequest.pin }}</b-badge>
                      <div>{{ $t('user-list.labels.esDe') }}</div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="pt-1 pb-1">
                  <b-col class="text-center">
                    <b-badge size="lg" variant="primary">
                      <h3 class="px-1 text-white">
                        {{
                          userData.withDrawlRequest.amount
                            | currency({
                              symbol: userData.withDrawlRequest.currency,
                            })
                        }}
                      </h3>
                    </b-badge>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <b-badge variant="danger"
                      >{{ $t('user-list.messages.confirmTransaction') }}</b-badge
                    >
                  </b-col>
                </b-row>
              </b-col>

              <b-col v-if="step === 3" style="padding: 20px">
                <b-row>
                  <b-col class="text-center">
                    <b-badge
                      v-if="withdrawalByPinStatus === false"
                      variant="danger"
                      >{{ $t('user-list.messages.failTransfer') }}</b-badge
                    >
                    <b-badge
                      v-else-if="withdrawalByPinStatus === true"
                      variant="success"
                      >{{ $t('user-list.messages.successTransfer') }}</b-badge
                    >
                    <b-badge v-else
                      >{{ $t('user-list.messages.processTransfer') }}</b-badge
                    >
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </validation-observer>

          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-end">
            <b-button
              v-if="showCancelButton"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-1"
              variant="outline-secondary"
              @click="hide"
              :disabled="loadings.componentLoading"
            >
              <feather-icon icon="XIcon" class="mr-1" />
              {{ $t("buttons.cancel") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-if="showActionButton"
              variant="primary"
              class="mr-2"
              :disabled="
                loadings.actionButton ||
                loadings.componentLoading ||
                actionButtonDisabled
              "
              @click="handleChangeStep"
            >
              <b-spinner
                v-if="loadings.actionButton"
                type="grow"
                label="Spinning"
                small
                class="mr-1"
              ></b-spinner>
              <feather-icon v-else :icon="actionButtonIcon" class="mr-1" />
              {{ actionButtonName }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <user-voucher ref="UserVoucher" />
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
  BTab,
  BTabs,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { computed, reactive, ref, getCurrentInstance } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@core/utils/validations/validations";
import store from "@/store";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import UserVoucher from "@/views/apps/user/users-list/UserVoucher.vue";
import { getUserData, validateTokenRefreshData } from "@/auth/utils";
import moment from "moment";

export default {
  name: "UserMoneyWithdrawalByPin",
  components: {
    UserVoucher,
    BAvatar,
    BForm,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BTab,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BTabs,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance()
    // const props = defineProps(['foo'])
    const toast = useToast(ToastificationContent);

    const UserVoucher = ref(null);

    const step = ref(0);
    const showWithdrawalByPinModal = ref(false);

    const actionButtonName = ref("Buscar");
    const actionButtonIcon = ref("SearchIcon");
    const showActionButton = ref(true);
    const showCancelButton = ref(true);

    const loadingPingText = ref("");

    const withdrawalByPinStatus = ref(false);

    const modalTittle = ref("");

    const userData = ref({
      _id: "",
      pinCode: "",
      withDrawlRequest: "",
    });

    const loadings = reactive({
      actionButton: false,
      componentLoading: false,
    });

    const setWithdrawalByPinModal = (flag) => {
      showWithdrawalByPinModal.value = flag;
      if (flag) modalTittle.value = vm.proxy.$t('user-list.text.retiroA') + userData.value.userName;
      else resetData();
    };

    const setUserData = (data) => {
      userData.value = Object.assign({}, data);
    };

    const resetData = () => {
      step.value = 0;
      showCancelButton.value = true
      withdrawalByPinStatus.value = false;
      loadings.actionButton = false;
      actionButtonIcon.value = "SearchIcon";
      actionButtonName.value = vm.proxy.$t('buttons.search');
      showActionButton.value = true;
      loadingPingText.value = "";
      userData.value = {
        _id: "",
        pinCode: "",
        withDrawlRequest: "",
      };
    };

    const showUserVoucher = (payload) => {
      UserVoucher.value.showModal(payload);
    };

    const { refFormObserver, getValidationState } = formValidation(resetData);

    const actionButtonDisabled = computed(() => {
      if (step.value === 0 && !userData.value.pinCode) {
        return true;
      }
      return false;
    });

    const handleChangeStep = async () => {
      if (step.value === 0 && userData.value.pinCode) {
        try {
          step.value = 1;
          loadings.actionButton = true;
          actionButtonIcon.value = "SearchIcon";
          actionButtonName.value = vm.proxy.$t('user-list.text.searching');
          modalTittle.value = vm.proxy.$t('user-list.text.searchingPin');
          loadingPingText.value = vm.proxy.$t('user-list.messages.verifyInfo');

          const withdrawal = await store.dispatch(
            "app-user/getWithdrawalByPin",
            {
              user: userData.value._id,
              pin: userData.value.pinCode,
            }
          );

          if (withdrawal.data) {
            userData.value.withDrawlRequest = withdrawal.data;
            actionButtonIcon.value = "CheckIcon";
            actionButtonName.value = "OK";
            modalTittle.value = vm.proxy.$t('user-list.text.foundPIN');
            loadingPingText.value = vm.proxy.$t('user-list.text.foundSolicitud');
            showCancelButton.value = false
          } else {
            showActionButton.value = false;
            modalTittle.value = vm.proxy.$t('user-list.text.notFoundPIN');
            loadingPingText.value = vm.proxy.$t('user-list.text.notFoundSolicitud');

            setTimeout(() => {
              setWithdrawalByPinModal(false);
            }, 2000);
          }
        } catch (e) {
          setWithdrawalByPinModal(false);
          notify(axiosErrorHandle(e), "danger");
        } finally {
          loadings.actionButton = false;
        }
      } else if (step.value === 1) {
        step.value = 2;
        actionButtonIcon.value = "UserCheckIcon";
        actionButtonName.value = vm.proxy.$t('user-list.buttons.confirmar');
        modalTittle.value = vm.proxy.$t('user-list.text.confirmWithdraw');
        showCancelButton.value = true
      } else if (step.value === 2) {
        try {
          step.value = 3;
          withdrawalByPinStatus.value = null;
          loadings.actionButton = true;
          actionButtonName.value = "z";
          modalTittle.value = vm.proxy.$t('user-list.text.confirmando');

          const makeWithdrawalByPin = await store.dispatch(
            "app-user/makeWithdrawalByPin",
            {
              withdrawalPin: userData.value.withDrawlRequest.pin,
              description: "none",
            }
          );

          withdrawalByPinStatus.value = true;
          actionButtonIcon.value = "DollarSignIcon";
          actionButtonName.value = vm.proxy.$t('user-list.text.confirmado');
          modalTittle.value = vm.proxy.$t('user-list.text.retiroConfirmado');
          notify(
            vm.proxy.$t('user-list.messages.seHaDebitado') +
              userData.value.withDrawlRequest.currency +
              " " +
              userData.value.withDrawlRequest.amount +
              vm.proxy.$t('user-list.messages.ofWalletof')+
              userData.value.userName,
            "success"
          );

          setTimeout(() => {
            showUserVoucher({
              date: moment(makeWithdrawalByPin.data.transaction.date).format(
                "DD/MM/YYYY hh:mm:ss A"
              ),
              ticketNumber: makeWithdrawalByPin.data.transaction._id,
              userNameDestination: getUserData().userName,
              userNameOrigin: userData.value.userName,
              amount: makeWithdrawalByPin.data.transaction.amount,
              typetTansaction: "DEBIT",
            });
            setWithdrawalByPinModal(false);
          }, 1500);

          emit("fetch-user-data");

          validateTokenRefreshData();
        } catch (e) {
          withdrawalByPinStatus.value = false;
          showActionButton.value = false;
          setTimeout(() => {
            setWithdrawalByPinModal(false);
          }, 2000);
          notify(axiosErrorHandle(e), "danger");
        } finally {
          loadings.actionButton = false;
        }
      }
    };

    const notify = (message, variant) => {
      toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "DollarSignIcon",
          variant: variant,
        },
      });
    };

    return {
      setWithdrawalByPinModal,
      actionButtonName,
      step,
      modalTittle,
      setUserData,
      showWithdrawalByPinModal,
      getValidationState,
      refFormObserver,
      userData,
      required,
      resetData,
      loadings,
      actionButtonIcon,
      handleChangeStep,
      showActionButton,
      loadingPingText,
      withdrawalByPinStatus,
      UserVoucher,
      actionButtonDisabled,
      showCancelButton
    };
  },
};
</script>

<style scoped></style>
