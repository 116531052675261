<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BModal,
  BTab,
  BTable,
  BTabs,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { translatableText, whitelabelPassRules } from "@core/utils/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import AccountSettingSecurity from "@/views/pages/account-setting/AccountSettingSecurity.vue";
import SocketInstance from "@/libs/socket-io/socket";

export default {
  name: "UserImport",
  components: {
    AccountSettingSecurity,
    BTab,
    BTabs,
    BLink,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BAlert,
    BButton,
    BFormFile,
    BModal,
    BCardSubTitle,
    BCard,
    BTable,
    BFormCheckbox,
    BCardTitle,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false, // Controls the visibility of a modal or section.
      walletsCsv: null, // Stores the CSV file for wallets.
      usersCsv: null, // Stores the CSV file for users.
      uploadingFile: false, // Indicates if a file is being uploaded.
      errorUploadingFile: false, // Indicates if there was an error uploading the file.
      showSuccessMessage: false, // Controls the visibility of the success message.
      successMessage: [], // Stores success messages.
      showWarningMessage: false, // Controls the visibility of the warning message.
      warningMessage: [], // Stores warning messages.
      importPassword: "", // Stores the password for user import.
      isPasswordValid: false, // Indicates if the password is valid.
      importUsers: true, // Controls if users are being imported.
      deleteImportUsersID: null, // Stores the ID of imported users to be deleted.
      processingFile: false, // Indicates if a file is being processed.
      currentImportType: "", // Stores the current import type (users or wallets).
      deletingImportedUsers: false, // Indicates if imported users are being deleted.
      processingFilePercent: 0, // Tracks the file processing percentage.
    };
  },
  watch: {
    importPassword() {
      this.validatePassword(); // Validates the password whenever it changes.
    },
  },
  sockets: {
    importStatus(data) {
      // Handles the import status updates from the server.
      this.processingFile = true;
      if (data.status === "completed") {
        this.processImportResults(data.results, this.currentImportType); // Processes the import results.
        this.showToast("Import completed.", "success"); // Shows a success toast.
        this.processingFile = false;
      } else if (data.status === "pending") {
        console.log("processing...");
        this.processingFilePercent = data.results.percentage; // Updates the processing percentage.
      } else if (data.status === "error") {
        this.errorUploadingFile = true;
        this.showToast("Import encountered errors.", "danger"); // Shows an error toast.
        this.processingFile = false;
      }
    },
  },
  computed: {
    whitelabelRule() {
      // Computes the whitelabel rule based on the selected whitelabel.
      const whitelabelSelected =
        this.$store.state.whitelabelCurrencyNabvar.whitelabel;
      return whitelabelPassRules(whitelabelSelected);
    },
    isFileUploadDisabled() {
      // Disables the file upload button if a file is uploading or the password is not valid.
      return this.uploadingFile || !this.isPasswordValid;
    },
  },
  methods: {
    async handleDeleteImportedUser() {
      try {
        await axios.delete("destroyAllPlayerImport"); // Deletes all imported users.
      } catch (e) {
        alert("error");
      }
    },
    async handleCsvFile(type, event) {
      // Handles the CSV file upload for users or wallets.
      this.uploadingFile = true;
      this.errorUploadingFile = false;
      this.showSuccessMessage = false;
      this.showWarningMessage = false;
      this.currentImportType = type;

      try {
        const apiUrlMap = {
          users: "/imports/users",
          wallets: "/imports/wallets",
        };

        if (!apiUrlMap[type]) {
          throw new Error("Invalid type");
        }

        const file = event.target.files[0];
        if (!file) {
          throw new Error("No file selected");
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append(
          "whitelabelId",
          this.$store.state.whitelabelCurrencyNabvar.whitelabel._id
        );

        if (type === "users") {
          formData.append("password", this.importPassword);
        }

        const response = await axios.post(apiUrlMap[type], formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        this.processingFile = true;
        this.showToast(
          "File uploaded successfully. Awaiting processing...",
          "info"
        );
      } catch (error) {
        this.errorUploadingFile = true;
        this.showToast(
          await translatableText({ text: axiosErrorHandle(error) }),
          "info"
        );
      } finally {
        this.uploadingFile = false;
        this.resetFileInput(type); // Resets the file input.
      }
    },
    processImportResults(results, type) {
      // Processes the import results and handles success and warning messages.
      this.showWarningMessage = results.some((item) => item.error);
      this.warningMessage = results
        .filter((item) => item.error)
        .map((item) => ({ errorMessage: item.error }));

      if (this.showWarningMessage) {
        const notImported = results.filter((item) => item.error);
        const message =
          type === "users"
            ? "The users import ended with errors"
            : "The wallets import ended with errors";
        this.showToast(message, "danger");
        if (type === "users") {
          this.exportToCSV(
            notImported.map((item) => item.user),
            "Users with failed import"
          );
        } else if (type === "wallets") {
          this.exportToCSV(
            notImported.map((item) => item.user.user_id),
            "Wallets with failed import"
          );
        }
      } else {
        this.showSuccessMessage = true;
        // if (type === "users") {
        //   this.successMessage = results.map(
        //     (item) => item.user.userName + " ok"
        //   );
        // } else if (type === "wallets") {
        //   this.successMessage = results.map(
        //     (item) => item.user.user_id + " ok"
        //   );
        // }
      }
    },
    showToast(message, variant) {
      // Displays a toast notification with the specified message and variant.
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Import",
            text: message,
            icon: "AlertTriangleIcon",
            variant: variant,
          },
        },
        {
          timeout: 8000, // Duration of the toast in milliseconds.
        }
      );
    },
    resetFileInput(type) {
      // Resets the file input field.
      const inputRefMap = {
        users: "inputUsersCsv",
        wallets: "inputWalletsCsv",
      };

      if (this.$refs[inputRefMap[type]]) {
        this.$refs[inputRefMap[type]].reset();
      }
    },
    async validatePassword() {
      // Validates the password using a form observer.
      if (this.$refs.observer) {
        const result = await this.$refs.observer.validate();
        this.isPasswordValid = result;
      }
    },
    async handleDeleteImportUsers() {
      // Deletes imported users based on the deleteImportUsersID.
      this.deletingImportedUsers = true;
      this.showSuccessMessage = false;
      try {
        const response = await axios.delete(
          "/destroyPlayerImport/" + this.deleteImportUsersID
        );
        this.showSuccessMessage = true;
        this.deletingImportedUsers = false;
      } catch (e) {
        this.deletingImportedUsers = false;
        this.showToast(
          await translatableText({ text: axiosErrorHandle(e) }),
          "info"
        );
      }
    },
    showModal(flag) {
      // Shows or hides the modal based on the flag.
      this.show = flag;
    },
    exportToCSV(arrayData, name) {
      // Exports the provided array data to a CSV file.
      console.log(arrayData);
      const csvContent = "data:text/csv;charset=utf-8," + arrayData.join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", name + ".csv");
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<template>
  <b-modal
    :visible="show"
    :hide-header-close="
      processingFile || uploadingFile || deletingImportedUsers
    "
    size="lg"
    cancel-variant="outline-secondary"
    centered
    :title="$t('modal_title')"
    hide-footer
    @hidden="showModal(false)"
  >
    <b-button @click="handleDeleteImportedUser()" v-show="true"
              class="mb-1"
      >Delete all</b-button
    >

    <b-alert variant="info" :show="processingFile">
      <h4 class="alert-heading">Importing</h4>
      <div class="alert-body">
        <b-progress :value="processingFilePercent" max="100" show-value>
          <b-progress-bar :value="processingFilePercent"
            >{{ processingFilePercent }}%</b-progress-bar
          >
        </b-progress>
      </div>
    </b-alert>

    <b-alert variant="success" dismissible :show="showSuccessMessage">
      <h4 class="alert-heading">
        {{ $t("success_message") }}
      </h4>
      <!--        <div class="alert-body">-->
      <!--          {{ successMessage }}-->
      <!--        </div>-->
    </b-alert>

    <b-alert variant="warning" dismissible :show="showWarningMessage">
      <h4 class="alert-heading">
        {{ $t("warning_message") }}
      </h4>
      <div class="alert-body d-flex flex-column">
        <span v-for="(wm, index) in warningMessage" :key="index">{{
          wm.errorMessage
        }}</span>
      </div>
    </b-alert>

    <b-alert
      variant="danger"
      class="p-1"
      dismissible
      :show="errorUploadingFile"
    >
      {{ $t("error") }}
    </b-alert>

    <b-tabs pills>
      <b-tab activate="">
        <!-- title -->
        <template #title>
          <div class="w-200p">
            <feather-icon icon="UploadIcon" size="18" class="" />
            <span class="font-weight-bold">{{ $t("import_users_title") }}</span>
          </div>
        </template>

        <b-card border-variant="primary" v-if="importUsers">
          <b-card-title class="card-tile-loading">
            <b-spinner small v-if="uploadingFile"></b-spinner>
            {{ $t("import_users_title") }}
          </b-card-title>
          <b-card-sub-title>
            {{ $t("import_users_subtitle") }}
          </b-card-sub-title>
          <b-card-body class="d-flex flex-column">
            <ValidationObserver ref="observer">
              <b-form-group>
                <label for="password">{{ $t("label_password") }}</label>
                <ValidationProvider
                  #default="{ errors }"
                  name="Password"
                  :rules="whitelabelRule"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="importPassword"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <b-form-file
                :disabled="isFileUploadDisabled || processingFile"
                ref="inputUsersCsv"
                v-model="usersCsv"
                accept=".csv"
                :placeholder="$t('users_file_placeholder')"
                drop-placeholder="Drop file here..."
                @change="handleCsvFile('users', $event)"
              />
            </ValidationObserver>

            <b-button
              class="mt-1 align-self-end text-danger"
              variant="link"
              @click="importUsers = false"
              >Eliminar usuarios importados</b-button
            >
          </b-card-body>
        </b-card>

        <b-card border-variant="danger" v-else>
          <b-card-title class="card-tile-loading">
            <b-spinner small v-if="uploadingFile"></b-spinner>
            {{ $t("delete_import_users_title") }}
          </b-card-title>
          <b-card-sub-title>
            {{ $t("delete_import_users_subtitle") }}
          </b-card-sub-title>
          <b-card-body>
            <ValidationObserver ref="observer">
              <b-form-group>
                <label for="id">ID</label>
                <ValidationProvider #default="{ errors }" name="id">
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="id"
                      v-model="deleteImportUsersID"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <div class="d-flex justify-content-end" style="gap: 8px">
                <b-button
                  :disabled="!deleteImportUsersID || processingFile"
                  variant="danger"
                  @click="handleDeleteImportUsers"
                  >{{ $t("buttons.delete") }}</b-button
                >
                <b-button variant="link" @click="importUsers = true">
                  {{ $t("import_users_title") }}</b-button
                >
              </div>
            </ValidationObserver>
          </b-card-body>
        </b-card>
      </b-tab>

      <b-tab activate="">
        <!-- title -->
        <template #title>
          <div class="w-200p">
            <feather-icon icon="UploadIcon" size="18" class="" />
            <span class="font-weight-bold">
              {{ $t("import_wallets_title") }}</span
            >
          </div>
        </template>

        <b-card border-variant="primary">
          <b-card-title class="card-tile-loading">
            <b-spinner small v-if="uploadingFile"></b-spinner>
            {{ $t("import_wallets_title") }}
          </b-card-title>
          <b-card-sub-title>
            {{ $t("import_wallets_subtitle") }}
          </b-card-sub-title>
          <b-card-body>
            <b-form-file
              :disabled="uploadingFile"
              ref="inputWalletsCsv"
              v-model="walletsCsv"
              accept=".csv"
              :placeholder="$t('wallets_file_placeholder')"
              drop-placeholder="Drop file here..."
              @change="handleCsvFile('wallets', $event)"
            />
          </b-card-body>
        </b-card>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<style scoped lang="scss">
.card-tile-loading {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
