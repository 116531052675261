<template>
  <b-modal
    :visible="isHistoryUserListActive"
    bg-variant="white"
    :title="$t('user-list.text.historial')"
    shadow
    backdrop
    no-close-on-backdrop
    hide-footer
    size="lg"
    @change="(val) => ShowSidebar(val)"
  >
    <template>
      <b-table
        md="9"
        :items="dataItems"
        :fields="fields"
        class="scroll"
        responsive
      >
      </b-table>
    </template>
  </b-modal>
</template>

<script>
import {
  BTab,
  TabsPlugin,
  BTabs,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSelect,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BCardText,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, onUnmounted, watch, computed } from "@vue/composition-api";
import { required, alphaNum, email, alphaDash } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import store from "@/store";
import { codeMultiple } from "../../../../forms/form-element/form-select/code";
import roleStoreModule from "../../../../pages/roles/roleStoreModule";
import clientStoreModule from "../../../../pages/clients/clientStoreModule";
import userStoreModule from "../../userStoreModule";
import whitelabelStoreModule from "../../../../pages/whitelabel/whitelabelStoreModule";
import { useToast } from "vue-toastification/composition";
import ComponentAddPlayer from "../ComponentAddPlayer.vue";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";

export default {
  components: {
    BTab,
    TabsPlugin,
    BTabs,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCardText,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ComponentAddPlayer,
    HierarchyUsers,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isHistoryUserListActive",
    event: "update:is-history-user-list-active",
  },
  props: {
    isHistoryUserListActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { label: "Origen", key: "origen" },
        { label: "Ticket Id", key: "referenceId" },
        { label: "Usuario", key: "user" },
        { label: "Fecha", key: "date" },
        { label: "Status", key: "ticketStatus" },
        { label: "Monto Apostado", key: "amount" },
      ],
      // solo por testing para eliminar console.error
      dataItems: [],
    };
  },
  computed: {},
  methods: {
    ShowSidebar(val) {
      this.$emit("update:is-history-user-list-active", val);
    },
  },
  mounted() {
    this.tabIndex = 0;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#add-new-user-sidebar___BV_modal_header_ {
  // background: linear-gradient(40deg, #e5710a, #f16029);
}

#add-new-user-sidebar___BV_modal_title_ {
  color: white !important;
}

.nav-tabs .nav-link.active {
  color: #001862;
}

.nav-pills .nav-link.active {
  background-color: #02cce0;
  border-color: #02cce0;
  box-shadow: 0 4px 18px -4px #001862;
}

.nav-tabs .nav-link:after {
  background: linear-gradient(30deg, #02cce0, #001862) !important;
}

// button[type="submit"] {
//   background-color: #e5710a !important;
//   border-color: #e5710a !important;
// }

// button[type="submit"]:hover {
//   box-shadow: 0 8px 25px -8px #f16029 !important;
// }

// button.btn.btn-outline-secondary,
// button.close {
//   border: 2px solid #e5710a !important;
//   color: #e5710a;
// }

// .modal-content {
//   width: 120% !important;
// }

[dir] .nav-pills li {
  width: 50%;
}
</style>
