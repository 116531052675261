<template>
  <div>
    <b-button variant="primary"  v-b-tooltip.hover.top="$t('tooltips.documentation_validation')" class="btn-icon rounded-circle" id="show-btn" @click="showModal" size="sm">
      <feather-icon icon="ImageIcon" />
    </b-button>

    <b-modal no-close-on-esc no-close-on-backdrop ref="my-modal" hide-footer :title="$t('labels.Documentation_validation')" size="xl">
      <div class="d-block text-center">
        <h3 v-if="!user.documents.fileId && !user.documents.fileAux">
          {{ $t('labels.user_no_upload') }}
        </h3>
        <h3 v-if="(user.documents.fileId && user.documents.fileAux )&& (!user.documents.fileIdValidate || !user.documents.fileAuxValidate)">
          {{ $t('labels.is_valid_document') }}
        </h3>
        <h3 class="text-success" v-if="user.documents.fileIdValidate && user.documents.fileAuxValidate">
          {{ $t('labels.is_valid_document_yes') }}
        </h3>
        <h3 class="text-danger" v-if="!user.documents.fileIdValidate || !user.documents.fileAuxValidate">
          {{ $t('labels.is_valid_document_no') }}
        </h3>
        
        <div  class="row">
          <div v-if="user.documents.fileId" class="col-12 col-lg-6 mb-3 mb-lg-0 row">
              <div class="col-12 row text-left d-flex justify-content-betwee">
                <div class="col-6"><strong>{{ $t('labels.username') }}:</strong></div>
                <div class="col-6"><p>  {{user.userName || '-'}} </p></div>

                <div class="col-6"><strong>{{ $t('labels.firstname') }}:</strong></div>
                <div class="col-6"><p>  {{user.firstName || '-'}} </p></div>

                <div class="col-6"><strong>{{ $t('labels.lastname') }}:</strong></div>
                <div class="col-6"><p>  {{user.lastName || '-'}} </p></div>

                <div class="col-6"><strong>{{ $t('labels.email') }}:</strong></div>
                <div class="col-6"><p>  {{user.email || '-'}} </p></div>
                
                <div class="col-6 pr-0 mr-0">
                  <strong>
                    {{ $t('labels.dateofbirth') }}:
                  </strong>
                </div>
                <div class="col-6 d-flex">
                  <p v-if="user.birthdate">{{ moment(user.birthdate) }}</p>
                  <p v-if="user.birthdate" class="ml-2">{{ age(user.birthdate) }} {{ $t('labels.years').toLowerCase() }}</p>
                  <p v-if="!user.birthdate"> - </p>
                </div>
                <div class="col-6"><strong>{{ $t('labels.nationality') }}:</strong></div>
                <div class="col-6"><p>  {{user.nationality || '-'}} </p></div>
              </div>
            <b-img style="width: 600px; height:400px" fluid rounded :src="user.documents.fileId"></b-img>
            <v-select
              label="label"
              :options="optionSelect"
              :reduce="(option) => option.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="selection.reasonId"
              :placeholder="$t('labels.select')"
              :clearable="false"
              class="mt-3 ml-1"
              style="width: 100%"
            />
          </div>
          <div v-if="user.documents.fileAux" class="col-12 col-lg-6 ">
            <div class="col-12 row text-left d-flex justify-content-betwee">
              <div class="col-6"><strong>{{ $t('labels.country') }}:</strong></div>
              <div class="col-6"><p>  {{user.country || '-'}} </p></div>

              <div class="col-6"><strong>{{ $t('labels.address') }}:</strong></div>
              <div class="col-6"><p>  {{user.address || '-'}} </p></div>
            </div>

            <b-img style="width: 600px; height:400px; margin-top:141px" fluid rounded :src="user.documents.fileAux"></b-img>
           
            <v-select
              label="label"
              :options="optionSelectAux"
              :reduce="(option) => option.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="selection.reasonAux"
              :placeholder="$t('labels.select')"
              :clearable="false"
              class="mt-3 ml-1"
              style="width: 100%"
            />
            
          </div>
        </div>
        <div v-if="message">
          <p class="text-warning">{{ message }}</p>
        </div>
      </div>

      <div class="d-flex">
        <b-button class="mt-3" variant="outline-warning" block @click="hideModal">{{ $t('labels.close') }}</b-button>
        <!-- <b-button v-if="user.documents.fileId && !user.documents.fileIdRejected" class="mt-3 ml-1" variant="outline-danger" block
          @click="rejected">Rechazar</b-button> -->
        <b-button v-if="user.documents.fileId && user.documents.fileAux" 
                  class="mt-3 ml-1" variant="outline-success" block @click="validated">{{ $t('user-list.text.validate') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, BImg } from "bootstrap-vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import userStoreModule from "../userStoreModule";
import store from "@/store";
import moment from "moment";
import vSelect from 'vue-select'
import { ref } from "@vue/composition-api";

export default {
  props: ["user"],
  data() {
    return {
      message: "",
      optionSelect: [
        { label: this.$t("select.approved"), value: 'approved' },
        { label: this.$t("select.younger"), value: 'younger' },
        { label: this.$t("select.unreadable_document"), value: 'unreadable_document' },
        { label: this.$t("select.incorrect_data"), value: 'incorrect_data' },
      ],
      optionSelectAux: [
        { label: this.$t("select.approved"), value: 'approved' },
        { label: this.$t("select.unreadable_document"), value: 'unreadable_document' },
        { label: this.$t("select.incorrect_data"), value: 'incorrect_data' },
      ],
      selection: {
        reasonId: null,
        reasonAux: null,
      },
    };
  },
  // filters: {
  //   moment: function (date) {
  //     if (!date) return " - ";
  //     return moment(date).format("DD/MM/YYYY");
  //   },
  //   age: function (date) {
  //     if (!date) return " - ";
  //     return moment().diff(date, "years");
  //   },
  //   reasons: function (reason) {
  //     return []
  //   },
  // },
  components: {
    BButton,
    BModal,
    BImg,
    vSelect
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    }
    const toast = useToast(ToastificationContent);
    return {
      toast,
    }
  },
  methods: {
    showModal() {
      this.selection.reasonId = this.user.documents.reasonId; 
      this.selection.reasonAux = this.user.documents.reasonAux;
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$emit("refetch-data");
      this.$refs["my-modal"].hide();
    },
    moment(dateofbirth) {
      return moment(dateofbirth).format("DD/MM/YYYY");
    },
    age: function (dateofbirth) {
      return moment().diff(dateofbirth, "years");
    },
    async validated() {
      try {
        let payload = {
          id: this.user._id,
          fileIdValidate: false,
          fileAuxValidate: false,
          reasonId: this.selection.reasonId,
          reasonAux: this.selection.reasonAux,
        }
        if(this.selection.reasonId == 'approved'){
          payload.fileIdValidate = true
        }
        if(this.selection.reasonAux == 'approved'){
          payload.fileAuxValidate = true
        }

        await store.dispatch("app-user/documentCheck", payload);
        this.$emit("refetch-data");

        this.toast({
          component: ToastificationContent,
          props: {
            title: this.$t('user-list.messages.userEdited'),
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.$refs["my-modal"].toggle("#toggle-btn");
      } catch (error) {
        this.message = this.$t('messages.unknown_error');
        console.log(error);
      }
    },
  },
};
</script>